import './App.css';
import { LoaderFunctionArgs, RouterProvider, createBrowserRouter, redirect, useRouteError } from 'react-router-dom';
import HomePage from './HomePage';
import { configureAutoTrack } from 'aws-amplify/analytics';
import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

configureAutoTrack({
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
  type: 'pageView',
});

function ErrorPage() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <center id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </center>
  );
}

const PolicyPageWrapper = () => {
  const [PolicyPage, setPolicyPage] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadComponent = async () => {
      const { default: LoadedPolicyPage } = await import('./PolicyPage');
      setPolicyPage(() => LoadedPolicyPage);
    };

    loadComponent();
  }, []);

  if (!PolicyPage) return <div>Loading...</div>;

  return <PolicyPage />;
};

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "policy",
        element: <PolicyPageWrapper />,
      },
      {
        path: "*",
        element: <HomePage />,
      }
    ],
  },
]);

export default function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}
