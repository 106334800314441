import { MessageOutlined, LockOutlined, LineChartOutlined, SearchOutlined } from "@ant-design/icons";
import MyLayout, { appStoreLink } from "./MyLayout";
import download from './Download_on_the_App_Store.svg';
import downloadAndroid from './google-play-badge-logo.svg';
import { Card, Col, Flex, Row, Typography, Carousel } from "antd";
import { Link } from "react-router-dom";
import { getRenderPropValue } from "antd/es/_util/getRenderPropValue";
const { Title, Text, Paragraph } = Typography;

export const redirectToAppStore = () => {
    window.location.href = 'https://apps.apple.com/us/app/ezmeal/id6479342414';
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
    <Col xs={24} sm={12} md={8}>
        <Card
            hoverable
            style={{ textAlign: 'center', height: '100%', padding: '16px' }}
            cover={icon}
        >
            <Card.Meta
                title={title}
                description={description}
            />
        </Card>
    </Col>
);

const colors = [
    '#FF6347',
    '#94f26f',
    '#FFD700',
]

const degrees = [
    'rotate(8deg)',
    'rotate(-3deg)',
    'rotate(5deg)',
]


const OneFeatureSection = ({ i, title, description, imageUrl }: { i: number, title: string; description: string; imageUrl: string }) => {
    return (
        <div className="feature-section" style={{
            marginTop: '80px',
        }}>
            <Row gutter={[24, 24]} justify="center">
                <Col xs={16} md={6}>
                    <div style={
                        {
                            transform: degrees[i],
                            transition: 'transform 0.5s ease',
                        }
                    }>
                        <img style={{
                            width: '220px',
                            borderStyle: 'solid',
                            borderWidth: '5px',
                            borderRadius: '10px',
                            borderColor: colors[i],
                        }} src={imageUrl} />
                    </div>
                </Col>
                <Col xs={24} md={8}>
                    <div style={{ marginLeft: '20px' }}>
                        <h1 style={{ color: colors[i], marginTop: '1rem' }}>{title}</h1>
                        <h3 style={{ fontSize: '16px', color: '#555' }}>{description}</h3>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const features = [
    {
        title: "Privacy? Absolutely!",
        description: "All your data stays right on your device. No sign-ups, no nosy logins. Just you and your calorie tracking.",
        imageUrl: "add_food.png",
    },
    {
        title: "Zap! Food Added",
        description: "Quickly add food with a barcode scan or search our extensive food database. Even scan menus for instant calorie counts.",
        imageUrl: "search_food.png",
    },
    {
        title: "Track Your Progress",
        description: "Fasting, shedding pounds, or logging meals, we're here cheering you on, every step of the way.",
        imageUrl: "track_progress.png",
    },
];

const FeaturesDisplay = () => {
    return (
        <div>
            {features.map((feature, index) => (
                <OneFeatureSection
                    key={index}
                    i={index}
                    title={feature.title}
                    description={feature.description}
                    imageUrl={feature.imageUrl}
                />
            ))}
        </div>
    );
};


const FeaturesSection: React.FC = () => (
    <div style={{ background: '#f0f2f5', padding: '50px 0' }}>
        <Row gutter={[16, 16]} justify="space-around">
            <FeatureCard
                icon={<LockOutlined style={{ fontSize: '48px', color: '#1890ff' }} />}
                title="Privacy First"
                description="Store your data locally on your device. With no accounts or logins required, your privacy is our top priority."
            />
            <FeatureCard
                icon={<SearchOutlined style={{ fontSize: '48px', color: '#1890ff' }} />}
                title="Add Food Easily"
                description="Quickly add food with a barcode scan or search our extensive food database. Even scan menus for instant calorie counts."
            />
            <FeatureCard
                icon={<LineChartOutlined style={{ fontSize: '48px', color: '#1890ff' }} />}
                title="Track Your Progress"
                description="Review your intake and fasting history at a glance. Keep track of your journey and adjust your goals accordingly."
            />
        </Row>
    </div>
);


const testimonials = [
    {
        name: "Jordan M.",
        text: "I've tried several calorie trackers, but this is by far the easiest to use. No complicated setup, no account needed. I was tracking my meals and calories within minutes of downloading!",
    },
    {
        name: "Alexa R.",
        text: "Finally, an app that doesn't want to know my life story just to help me track my calories. I love that all my data stays on my device and I don't have to worry about privacy breaches.",
    },
    {
        name: "Samir K.",
        text: "This app has everything I was looking for and more. Barcode scanning makes adding food so easy, and the menu scanning feature was a game changer for me.",
    },
    {
        name: "Mike L.",
        text: "Seeing my progress over time has been incredibly motivating. I love being able to quickly add my current weight and see how my eating habits are impacting my weight loss journey.",
        affiliation: "Freelancer",
    }
];

const TestimonialsSection: React.FC = () => (
    <Row justify="center" style={{ padding: '10px 15px', maxWidth: '100%', margin: '0' }} >
        <Col span={24} md={20}>
            <Carousel style={{ maxWidth: '100%', height: 'auto' }} autoplay dots={false}>
                {testimonials.map((testimonial, index) => (
                    <div key={index}>
                        <Card>
                            <Paragraph>"{testimonial.text}"</Paragraph>
                            <Text strong>{testimonial.name}</Text>
                        </Card>
                    </div>
                ))}
            </Carousel>
        </Col>
    </Row >
);

const GetStartButton: React.FC = () => (
    <Link style={{ marginTop: '10px' }} to={appStoreLink}><img alt="download_ios" src={download} /></Link>
);

const GetStartButtonAndroid: React.FC = () => (
    <Link to={appStoreLink}><img style={{ height: '132px', marginLeft: '20px', marginTop: '-36px' }} alt="download_android" src={downloadAndroid} /></Link>
);

const DownloadSection: React.FC = () => (
    <Flex style={{ textAlign: 'center' }}>
        <GetStartButton />
        {/* <div ><GetStartButtonAndroid /></div> */}
    </Flex>
);

const HomePage: React.FC = () => {
    return (
        <MyLayout InnerComponent={
            <Flex justify="center" align="center" vertical>
                <Title level={1}>Track Your Calories, Not Your Data</Title>
                <Title level={3}>Your all-in-one calorie and fasting tracker - no login required, total privacy</Title>
                <div style={{ height: "25px" }} />
                <DownloadSection />
                <div style={{ height: "15px" }} />
                <div style={{
                    backgroundImage: 'url(/phone2.webp)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '200px',
                    height: '400px',
                    marginTop: '10px',
                    marginBottom: '45px'
                }}>
                    <img style={{
                        width: '169px',
                        marginLeft: '16px',
                        marginTop: '36px',
                        borderStyle: 'solid',
                        borderBottomWidth: '22px'
                    }} src="sms.jpg" />
                </div>
                <FeaturesSection />
                <FeaturesDisplay />
                <div style={{ height: "50px" }} />
                <DownloadSection />
                <TestimonialsSection />
            </Flex >
        } />
    );
}

export default HomePage;
